<template>
  <div class="addLine">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{id ==''?'添加礼包':'编辑礼包'}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="formJbxx" :rules="jbxx" :model="formList" label-width="80px">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane name="1" label="基本信息">
          <el-form-item label="主标题" prop="title">
            <el-input
              v-model="formList.title"
              placeholder="请输入主标题"
            ></el-input>
          </el-form-item>
          
          <el-form-item label="副标题" prop="secondTitle">
            <el-input
              v-model="formList.secondTitle"
              placeholder="请输入副标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="推荐理由" prop="shareTitle">
            <el-input
              v-model="formList.shareTitle"
              placeholder="请输入推荐理由"
            ></el-input>
          </el-form-item>
          <el-form-item label="原价" prop="oldFee">
            <el-input
              type="number"
              @input="onInputChange"
              v-model.trim="formList.oldFee"
              placeholder="请输入原价"
            ></el-input>
          </el-form-item>
          <el-form-item label="售价" prop="packFee">
            <el-input
              type="number"
              @input="onInputChange"
              v-model.trim="formList.packFee"
              placeholder="请输入售价"
            ></el-input>
          </el-form-item>

          <el-form-item label="所属分类">
            <el-select
              @clear="onClear(2)"
              clearable
              v-model="formList.cateId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in cateListA"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标签" prop="label">
            <el-input
              v-model="formList.label"
              placeholder="请输入标签名,多个标签可用 , 隔开"
            ></el-input>
          </el-form-item>
          <el-form-item label="销量" prop="saleCount">
            <el-input
              type="number"
              @input="onInputChange"
              v-model.trim="formList.saleCount"
              placeholder="请输入销量"
            ></el-input>
          </el-form-item>
          <el-form-item label="限购数量" prop="quotaCount" style="margin-bottom:0px">
            <el-input
              type="number"
              @input="onInputChange"
              v-model.trim="formList.quotaCount"
              placeholder="请输入限购数量"
            ></el-input>
          </el-form-item>
          <el-form-item label="" style="height: 50px; margin: 0"
        ><span style="color: red"
          >一个客户可购买的数量:0表示不限制,1表示可以购买一次,2表示可以购买两次,以此类推</span
        ></el-form-item
      >
          <el-form-item label="库存" prop="stock">
            <el-input
              type="number"
              @input="onInputChange"
              v-model.trim="formList.stock"
              placeholder="请输入库存"
            ></el-input>
          </el-form-item>
          <el-form-item label="活动时间">
            <el-date-picker
              v-model="formList.beginTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="请选择活动开始时间"
            >
            </el-date-picker>
            -
            <el-date-picker
              v-model="formList.endTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="请选择活动结束时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="产品使用时间">
            <el-date-picker
              v-model="formList.useBeginTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="请选择使用开始时间"
            >
            </el-date-picker>
            -
            <el-date-picker
              v-model="formList.useEndTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="请选择使用结束时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="提前预定天数" prop="advanceDay">
            <el-input
              type="number"
              @input="onInputChange"
              v-model.trim="formList.advanceDay"
              placeholder="请输入提前预定天数"
            ></el-input>
          </el-form-item>
          <el-form-item label="分佣设置">
            <el-radio v-model="formList.profitWay" :label="1">核销一张得一张佣金</el-radio>
            <el-radio v-model="formList.profitWay" :label="2">核销一张得全部佣金</el-radio>
          </el-form-item>
          <el-form-item label="是否显示">
            <el-radio v-model="formList.isShow" :label="1">是</el-radio>
            <el-radio v-model="formList.isShow" :label="0">否</el-radio>
          </el-form-item>
          <el-form-item label="是否享受会员折扣">
            <el-radio v-model="formList.isUser" :label="1">是</el-radio>
            <el-radio v-model="formList.isUser" :label="0">否</el-radio>
          </el-form-item>
          <el-form-item label="万旅网是否显示">
            <el-radio v-model="formList.wlShow" :label="1">是</el-radio>
            <el-radio v-model="formList.wlShow" :label="0">否</el-radio>
          </el-form-item>
          <el-form-item label="是否仅限新用户购买">
        <el-radio v-model="formList.isNew" :label="1">是</el-radio>
        <el-radio v-model="formList.isNew" :label="0">否</el-radio>
      </el-form-item>
          <el-form-item label="游戏ID" prop="content">
            <el-input
              v-model="formList.gameId"
              placeholder="购票成功后是否参与游戏活动  0 为不参与"
            ></el-input>
          </el-form-item>

          <!-- <el-form-item label="评分" class="col" prop="score">
              <el-input
                v-model.trim="formList.score"
                @input="onInputChange"
                placeholder="请输入天数"
              ></el-input>
            </el-form-item> -->

          <div class="btn">
            <el-button type="primary" @click="onNext('2')">下一步</el-button>
            <el-button type="primary" @click="onSave" v-show="Number(id) !== 0"
              >保存</el-button
            >
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane name="2" label="详情图片">
          <el-form-item label="封面图" prop="content">
            <send-image
              type="one"
              @addImageStr="(e) => addImageStr(e, 1)"
              :images="formList.showImg"
            ></send-image>
            <i>每张图片不能超过500K，建议尺寸：335*172px</i>
          </el-form-item>
          <el-form-item label="分享图" prop="content">
            <send-image
              type="one"
              @addImageStr="(e) => addImageStr(e, 2)"
              :images="formList.shareImg"
            ></send-image>

            <i>每张图片不能超过500K，建议尺寸：500*400px</i>
          </el-form-item>
          <el-form-item label="轮播图" prop="content">
            <send-image
              type="more"
              @addImageStrs="addImageStrs"
              :images="formList.imgUrl.join(',')"
            ></send-image>

            <i>每张图片不能超过500K，建议尺寸：750*500px</i>
          </el-form-item>

          <div style="display: flex">
              <div style="width: 100px">图文说明</div>
              <VueUeditorWrap
                v-model="formList.details"
                :config="myConfig"
                ref="myTextEditor"
              ></VueUeditorWrap>
            </div>
          

          <div class="btn">
            <el-button type="primary" @click="onPre('1')">上一步</el-button>
            <el-button type="primary" @click="onNext('3')">下一步</el-button>
            <el-button type="primary" @click="onSave" v-show="Number(id) !== 0"
              >保存</el-button
            >
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane name="3" label="预定须知">
          <template>
            <div style="display: flex">
              <div style="width: 100px">使用说明</div>
              <VueUeditorWrap
                v-model="formList.useNotice"
                :config="myConfig"
                ref="myTextEditor"
              ></VueUeditorWrap>
            </div>
            <div style="display: flex">
              <div style="width: 100px">预定须知</div>
              <VueUeditorWrap
                v-model="formList.bookNotice"
                :config="myConfig"
                ref="myTextEditor"
              ></VueUeditorWrap>
            </div>
            <div style="display: flex">
              <div style="width: 100px">退改说明</div>
              <VueUeditorWrap
                v-model="formList.refundNotice"
                :config="myConfig"
                ref="myTextEditor"
              ></VueUeditorWrap>
            </div>
          </template>

          <div class="btn">
            <el-button type="primary" @click="onPre('2')">上一步</el-button>
            <!-- <el-button type="primary" @click="onNext('5')">下一步</el-button> -->

            <el-button type="primary" @click="onSave">保存</el-button>
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane name="5" label="预订须知">
            <div style="display: flex">
              <div style="width: 100px">预订须知</div>
              <VueUeditorWrap
                v-model="formList.bookNotice"
                :config="myConfig"
                ref="myTextEditor"
              ></VueUeditorWrap>
            </div>
  
            <div style="display: flex">
              <div style="width: 100px">特别说明</div>
              <VueUeditorWrap
                v-model="formList.specialNotice"
                :config="myConfig"
                ref="myTextEditor"
              ></VueUeditorWrap>
            </div>
            <div style="display: flex">
              <div style="width: 100px">安全说明</div>
              <VueUeditorWrap
                v-model="formList.saveNotice"
                :config="myConfig"
                ref="myTextEditor"
              ></VueUeditorWrap>
            </div>
            <div style="display: flex">
              <div style="width: 100px">温馨提示</div>
              <VueUeditorWrap
                v-model="formList.reminder"
                :config="myConfig"
                ref="myTextEditor"
              ></VueUeditorWrap>
            </div>
            <div class="btn">
              <el-button type="primary" @click="onPre('4')">上一步</el-button>
              <el-button type="primary" @click="onSave">保存</el-button>
              <el-button type="primary" @click="$router.go(-1)">返回</el-button>
            </div>
          </el-tab-pane> -->
      </el-tabs>
    </el-form>
  </div>
</template>
  <script>
const platform = [
  { value: "1", name: "小程序" },
  { value: "2", name: "H5" },
  { value: "3", name: "IOS" },
  { value: "4", name: "Android" },
  { value: "5", name: "PC" },
];
import { getClassifyList ,addPackage,getPackageDetail} from "../../../api/seckillInfo";
import quillConfig from "../../../../quill-config";
import { selectAllByFatherId } from "../../../api/travel";
import { addLine, lineDetails } from "../../../api/lineList";

import { sendFile } from "../../../api/sendFile";
import sendImage from "../../../components/sendImage.vue";
import { Quill } from "vue-quill-editor";
//   import { supList } from "../../api/supList.js";
import VueUeditorWrap from "vue-ueditor-wrap";
//   import { couponAllCateAPI } from "../../api/coupon";
import draggable from "vuedraggable";
export default {
  components: { sendImage, VueUeditorWrap, draggable },
  name: "addLine",
//   props: {
//     id: {
//       type: [Number, String],
//     },
//   },

  data() {
    return {
      checkAll: false,
      load:false,
      checked: [],
      checkVal: [],
      platform: platform,
      supplierOptions: [{ cateId: 0, cateName: "自营" }],
      myConfig: {
        toolbars: [
          [
            "simpleupload", //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 600, // 初始容器高度
        initialFrameWidth: "50%", // 初始容器宽度
        serverUrl: "/config",
        UEDITOR_HOME_URL: "/static/UEditor/",
        maximumWords: "100000000",
      },
      id:'',
      imgList: [],
      detailsImgList: [],
      activeName: "1",
      cateValue: null,
      cateValue1: null,
      cateListA: [],
      jmsgRules: {},
      formList: {
        title: "",
        secondTitle: "",
        shareTitle: "",
        cateId: null,
        oldFee: "",
        packFee: "",
        label: "",
        saleCount: "",
        quotaCount:'',
        stock: "",
        beginTime:'',
        endTime:'',
        useBeginTime:'',
        useEndTime:'',
        advanceDay:'',
        profitWay:1,
        isShow:1,
        gameId:'',
        showImg:'',
        shareImg:'',
        imgUrl:[],
        useNotice:'',
        bookNotice:'',
        refundNotice:'',
        details:'',
        isUser:0,
        wlShow:1,
        isNew:0
      },
      jbxx: {
        secondTitle: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        shareTitle: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        oldFee: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        packFee: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        label: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        saleCount: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        quotaCount:[
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        stock: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        advanceDay: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
      },
    };
  },
//   mounted() {
//     quillConfig.register(Quill);
//     quillConfig.initButton();
//   },
  // watch: {
  //   cateValue: async function () {
  //     // this.cateValue1 = "";
  //     // this.formList.cateId = "";
  //     const { data } = await selectAllByFatherId({
  //       fatherId: this.cateValue,
  //     });
  //     this.cateListB = data.data;
  //   },
  //   cateValue1: async function () {
  //     if (!this.cateValue1) return;
  //     // this.formList.cateId = "";
  //     const { data } = await selectAllByFatherId({
  //       fatherId: this.cateValue1,
  //     });
  //     this.cateListC = data.data;
  //   },
  //   imgList: function (newVal, oldVal) {
  //     console.log(newVal, "new", oldVal, "old");
  //     this.formList.travelImg = this.imgList.join(",");
  //   },
  //   detailsImgList: function () {
  //     this.formList.details = this.detailsImgList.join(",");
  //   },
  //   radiovalue: function () {
  //     this.formList.unsubscribe = Number(this.radiovalue);
  //   },
  // },
  created() {
    this.getTravelCate();
    this.id = this.$route.query.packId || 0;
    console.log(this.id)
    if (this.id != "0" || this.id != 0) {
      this.getLineDetails();
    }
  },
  methods: {
    async getLineDetails() {
      const { data } = await getPackageDetail({
        packId: Number(this.id),
      });
      Object.keys(this.formList).forEach((key) => {
        this.formList[key] = data.data[key];
      });
      this.formList.imgUrl = data.data.imgUrl || [];
      this.formList.label = data.data.label.join(',');
    },
    async getTravelCate() {
      const { data } = await getClassifyList();
      this.cateListA = data.data;
    },
    addImageStr(image, type) {
      if (type === 1) {
        this.formList.showImg = image;
      } else if (type === 2) {
        this.formList.shareImg = image;
      }
    },
    addImageStrs(image) {
      this.formList.imgUrl = image;
    },
    onClose() {
      this.formList.showImg = "";
    },
    onClose1(item) {
      this.imgList = this.imgList.filter((value) => {
        return value != item;
      });
    },
    onClose2(item) {
      // this.formList.details = "";
      this.detailsImgList = this.detailsImgList.filter((value) => {
        return value != item;
      });
    },
    onInputChange(value) {
      if (isNaN(value)) {
        this.$message({
          showClose: true,
          message: "请输入数字！",
          type: "warning",
        });
      }
    },
    onSave() {
      this.$refs.formJbxx.validate(async (validate) => {
        if (!validate) {
          return;
        }
        // return;
        if (this.load) return;
        const params = {
          ...this.formList,
          imgUrl: this.formList.imgUrl.join(","),
          packId: this.id,
        };
        this.load = true;

        const { data } = await addPackage(params);
        this.load = false;
        console.log(data);
        if (data.code == 0) {
          this.$message({
            showClose: true,
            message: data.msg,
            type: "success",
          });
          this.$router.go(-1);
        } else {
          this.$message({
            showClose: true,
            message: data.msg,
            type: "error",
          });
        }
      });

      // this.formList.pcImg = this.formList.pcImg.join(",");
    },
    onNext(id) {
      this.activeName = id;
    },
    onPre(id) {
      this.activeName = id;
    },
    onClear(id) {
      if (id == 1) {
        this.list.status = null;
      } else {
        this.formList.cateId = null;
      }
    },
  },
};
</script>
  <style lang="less" scoped>
.addLine {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }

  .el-tabs {
    margin-top: 50px;

    .btn {
      width: 400px;
      margin-left: 400px;
    }
  }

  /deep/.el-form {
    #cols {
      .el-form-item__content {
        flex-wrap: unset !important;
      }
    }
    .col {
      display: inline-block;

      .el-form-item__content {
        .el-input {
          width: 100%;
        }
      }
    }

    /deep/.el-form-item {
      .el-form-item__content {
        .el-select {
          width: 270px;

          .el-input {
            width: 265px;
            max-width: 90%;
          }
        }

        .el-radio {
          margin: 10px 0 0 20px;
        }

        display: flex !important;
        flex-wrap: wrap !important;

        .img {
          position: relative;
          margin: 0 20px;
          height: 100px;
          width: 100px;
          margin-top: 10px;

          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }

          div {
            position: absolute;
            top: 0;
            right: 5px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }

        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          margin-top: 10px;

          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }

        i {
          // margin-left: 20px;
          color: red;
        }

        .el-input {
          max-width: 800px;
        }
      }
    }
  }

  /deep/.tdgz {
    .el-form-item {
      .el-form-item__content {
        display: block !important;

        div {
          margin-top: 20px;

          .el-input {
            display: inline-block;
            max-width: 200px;
          }
        }
      }
    }
  }

  /deep/.quill-editor {
    height: 400px;

    .ql-container {
      height: 350px;
    }
  }

  .form_box {
    display: flex;

    /deep/ .el-form-item__content {
      margin-left: 0 !important;
    }

    .item {
      flex: 1;

      &:last-child {
        margin-left: 30px;
      }

      /deep/.item_inp {
        width: 250px !important;
      }
    }
  }
}
</style>
  